import React, { useState, useRef, useEffect } from "react";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import accessibility from "highcharts/modules/accessibility";
import leftArrowIcon from "./../../assets/images/icon-arrow-left.png";
import rightArrowIcon from "./../../assets/images/icon-arrow-right.png";
import IconWellnessScore from "./../../assets/images/icon-wellness-score.svg";
import {
  IntervalTimes,
  Pagination,
  SortOrders,
  SmartScanGraphDataPropertyIndices
} from "../../constants";
import { getSmartScanWellnessScores_Ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  isEmptyArray,
  isSmartScanScorePerfect
} from "../../helpers/utils";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import WellnessScoreSummery from "./WellnessScoreSummery";
import SmartScanImages from "./SmartScanImages";

const SmartScanGraph = (props) => {
  const [smartScanData, setSmartScanData] = useState();
  const [smartScanGraphData, setSmartScanGraphData] = useState([]);
  const smartScanGraphDataRef = useRef([]);
  const [smartScanTotalCount, setSmartScanTotalCount] = useState(0);
  const {
    patientId,
    onGraphPointClicked,
    smartScanId,
    refreshGraph,
    setUserHasSmartScan = () => {}
  } = props;
  const chartRef = useRef();
  const [currentPageNum, setCurrentPageNum] = useState(Pagination.FirstPageNo);
  const currentPageNumRef = useRef(Pagination.FirstPageNo);
  const dispatch = useDispatch();
  const totalPageCount = useRef();
  const location = useLocation();
  const [selectedSmartScan, setSelectedSmartScan] = useState(null);
  const [smartScanScore, setSmartScanScore] = useState(false);
  const initialGraphScores = [51, 59, 60, 65, 75, 85];
  const initialGraphCategories = [
    "JUN 5<br>2021",
    "FEB 12<br>2021",
    "MAR 18<br>2021",
    "APR 25<br>2021",
    "MAY 20<br>2021",
    "JUN 20<br>2021"
  ];
  const chartMaxHeight = 80;
  const deviceMinWidth = 360;
  const chartStandardHeight = (13 / 16) * 100;

  accessibility(Highcharts);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      spacingLeft: 25,
      spacingRight: 30,
      height: getChartHeightInPercentageBasedOnDeviceWidth() + "%",
      panning: false
    },
    title: {
      useHTML: true,
      enabled: true,
      text: null
    },
    xAxis: {
      categories: [],
      max: Pagination.ItemPerPage - 1
    },
    yAxis: {
      title: {
        text: ""
      },
      tickInterval: 20,
      min: -60,
      max: 100,
      lineColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, "rgb(60, 182, 73)"],
          [0.1, "rgb(252, 237, 33)"],
          [0.2, "rgb(248, 166, 30)"],
          [0.3, "rgb(255, 2, 0)"]
        ]
      },
      lineWidth: 5
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              handleGraphPointClick(this.index);
            }
          }
        }
      },
      area: {
        lineColor: "#666666",
        lineWidth: 0,
        marker: {
          lineWidth: 1,
          lineColor: "#666666"
        },
        dataLabels: {
          enabled: true
        }
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: "area",
        name: "Score",
        data: []
      }
    ],
    accessibility: {
      description:
        "This chart represents wellness score of the all smartscans of the patient"
    }
  });

  function getChartHeightInPercentageBasedOnDeviceWidth() {
    const width = window.innerWidth;
    let height = 0;
    if (width <= deviceMinWidth) {
      height = chartMaxHeight;
    } else {
      height = chartStandardHeight;
    }

    return height;
  }

  function handleGraphPointClick(index) {
    if (index >= 0) {
      let smartScanScoreInfo = smartScanGraphDataRef.current[index];
      if (smartScanScoreInfo) {
        setSelectedSmartScan(smartScanScoreInfo);
        if (onGraphPointClicked) {
          onGraphPointClicked(smartScanScoreInfo);
        }
      }
    }
  }

  useEffect(() => {
    if (patientId > 0) {
      resetSmartScansScores();
    }
  }, [patientId]);

  useEffect(() => {
    if (currentPageNum > 0 && patientId > 0) {
      getSmartScanWellScores();

      if (refreshGraph) {
        const interval = setInterval(() => {
          getSmartScanWellScores();
        }, IntervalTimes.Minute_MS_30);

        return () => clearInterval(interval);
      }
    }
  }, [currentPageNum, patientId]);

  useEffect(() => {
    if (smartScanTotalCount > 0) {
      totalPageCount.current = Math.ceil(
        smartScanTotalCount / Pagination.ItemPerPage
      );
    }
  }, [smartScanTotalCount]);

  useEffect(() => {
    if (!isEmptyArray(smartScanData) && smartScanTotalCount > 0) {
      let graphData = prepareGraphData(smartScanData);

      if (graphData) {
        smartScanGraphDataRef.current = [...graphData];
        setSmartScanGraphData(smartScanGraphDataRef.current);
      }
    }
  }, [smartScanData, smartScanTotalCount]);

  useEffect(() => {
    if (smartScanData && !isEmptyArray(smartScanGraphData)) {
      setChartOptions((prevOptions) => {
        return {
          ...prevOptions,
          xAxis: {
            categories: !isEmptyArray(smartScanGraphData)
              ? smartScanGraphData?.map(
                  (sc) => sc[SmartScanGraphDataPropertyIndices.CreatedOn]
                )
              : [],
            max: Math.min(smartScanGraphData.length, Pagination.ItemPerPage) - 1
          },
          series: [
            {
              data: !isEmptyArray(smartScanGraphData) ? smartScanGraphData : []
            }
          ]
        };
      });
    }
  }, [smartScanGraphData]);

  useEffect(() => {
    if (chartOptions) {
      ShowHideNavigtionArrow();
    }
  }, [chartOptions]);

  function resetSmartScansScores() {
    currentPageNumRef.current = 0;
    totalPageCount.current = 0;
    setCurrentPageNum(1);
    setSmartScanData([]);
    setSmartScanTotalCount(0);
    setSmartScanGraphData([]);
    smartScanGraphDataRef.current = [];
    setChartOptions((prevOptions) => {
      return {
        ...prevOptions,
        xAxis: {
          categories: initialGraphCategories
        },
        series: [
          {
            data: initialGraphScores
          }
        ]
      };
    });
    setSelectedSmartScan(null);
  }

  function getSmartScanWellScores() {
    if (patientId > 0 && currentPageNum > 0) {
      let params = {
        itemPerPage: Pagination.ItemPerPage,
        currentPageNum: currentPageNum,
        sortingOrder: SortOrders.Descending
      };

      dispatch(showLoadingSpinner());
      getSmartScanWellnessScores_Ajax(
        patientId,
        params,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response && response.success && response.data) {
            setSmartScanData(response.data.smartScanScores);
            setSmartScanTotalCount(response.data.totalCount);

            if (setUserHasSmartScan) {
              setUserHasSmartScan(true);
            }
          } else if (!response.success) {
            if (setUserHasSmartScan) {
              setUserHasSmartScan(false);
            }
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function prepareGraphData(smartScanData) {
    if (!isEmptyArray(smartScanData)) {
      let smartScanGraphData = smartScanData.map((smartScan) => {
        return [
          moment(smartScan.createdOn).format("MMM DD<br>YYYY"),
          smartScan.dentalCondition.score,
          smartScan.smartScanId,
          smartScan.patientId
        ];
      });

      return smartScanGraphData;
    }
  }

  function ShowHideNavigtionArrow() {
    if (chartRef?.current?.chart) {
      let chart = chartRef.current.chart;
      let leftArrow = chart?.leftArrow;
      let rightArrow = chart?.rightArrow;

      if (leftArrow) {
        if (currentPageNumRef.current < totalPageCount.current) {
          leftArrow.show();
        } else {
          leftArrow.hide();
        }
      }

      if (rightArrow) {
        if (currentPageNumRef.current > Pagination.FirstPageNo) {
          rightArrow.show();
        } else {
          rightArrow.hide();
        }
      }
    }
  }

  function OnLeftArrowClicked() {
    if (currentPageNumRef.current <= totalPageCount.current) {
      setCurrentPageNum((prev) => {
        currentPageNumRef.current = prev + 1;
        return prev + 1;
      });
    }
  }

  function onRightArrowClicked() {
    if (currentPageNumRef.current > Pagination.FirstPageNo) {
      setCurrentPageNum((prev) => {
        currentPageNumRef.current = prev - 1;
        return prev - 1;
      });
    }
  }

  useEffect(() => {
    if (chartRef?.current?.chart) {
      let chart = chartRef?.current?.chart;

      if (smartScanTotalCount > Pagination.ItemPerPage) {
        chart.leftArrow = chart.renderer
          .image(
            leftArrowIcon,
            0,
            getChartHeightInPercentageBasedOnDeviceWidth(),
            30,
            30
          )
          .attr({ zIndex: 10 });
        chart.rightArrow = chart.renderer
          .image(
            rightArrowIcon,
            chart.chartWidth - 30,
            getChartHeightInPercentageBasedOnDeviceWidth(),
            30,
            30
          )
          .attr({ zIndex: 10 });
        chart.leftArrow.on("click", OnLeftArrowClicked).add();
        chart.rightArrow.on("click", onRightArrowClicked).add();
      } else if (smartScanTotalCount <= Pagination.ItemPerPage) {
        if (chart?.leftArrow) {
          chart.leftArrow.destroy();
          chart.leftArrow = null;
        }

        if (chart?.rightArrow) {
          chart.rightArrow.destroy();
          chart.rightArrow = null;
        }
      }
    }
  }, [smartScanTotalCount]);

  function hasSmartScans() {
    return !isEmptyArray(smartScanGraphData);
  }

  function getSelectedSmartScanId() {
    if (selectedSmartScan && !isEmptyArray(selectedSmartScan)) {
      return selectedSmartScan[SmartScanGraphDataPropertyIndices.SmartScanId];
    }
    return smartScanId > 0 ? smartScanId : 0;
  }

  return (
    <div className="smart-scan-card patient-member-card mb-3">
      <div className="border-radius-xlg bg-white">
        <div className="card text-body text-decoration-none d-flex flex-row align-items-lg-center border-0 p-3">
          <div className="card-header flex-shrink-0 p-0 border-0">
            <img src={IconWellnessScore} alt="" />
          </div>
          <div
            className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center flex-grow-1 px-3 px-md-4 py-0 border-0"
            id="wellness-current-value"
          >
            <h3 className="col-12 col-lg-2 card-title pe-lg-4 mb-4 mb-lg-0">
              Your Wellness Score
            </h3>

            <WellnessScoreSummery
              patientId={patientId}
              refreshGraph={refreshGraph}
              smartScanId={getSelectedSmartScanId()}
              setSmartScanScore={setSmartScanScore}
            />

            <div className="col-12 col-lg-6">
              <div
                className={
                  "highcharts-figure d-lg-block " +
                  (hasSmartScans() ? "" : "opacity-50 filter-grayscale")
                }
              >
                <div id="score-chart">
                  <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={chartOptions}
                  />
                </div>
                {location &&
                location.pathname == "/smart-scan-wellness-score" ? (
                  <div className="chart-bottom-text d-none">
                    You're improving your oral health - keep up the good work!
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex">
                <Link
                  to={"/smart-scan-works"}
                  className="btn btn-secondary btn-xs mx-auto mt-2"
                  tabIndex={0}
                >
                  Start New Scan
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isSmartScanScorePerfect(smartScanScore) && (
          <SmartScanImages smartScanId={getSelectedSmartScanId()} />
        )}
      </div>
    </div>
  );
};

export default SmartScanGraph;
