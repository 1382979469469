import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { getSmartScanWellnessScoreSummery_Ajax } from "../../helpers/requests";
import { handleApiErrors, formatDateTime } from "../../helpers/utils";
import { IntervalTimes, DateTimeFormats } from "../../constants";
import DummyWellnessSummeryScore from "./DummyWellnessSummeryScore";
import { useNavigate } from "react-router-dom";

const WellnessScoreSummery = (props) => {
  const { patientId, refreshGraph, smartScanId, setSmartScanScore } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentWellnessScoreSummeryData, setCurrentWellnessScoreSummeryData] =
    useState();
  const [prevWellnessScoreSummeryData, setPrevWellnessScoreSummeryData] =
    useState();
  const [
    smartScanWellnessScoreChangeData,
    setSmartScanWellnessScoreChangeData
  ] = useState(0);
  const [previousSmartScanDateData, setPreviousSmartScanDateData] = useState();
  const [currentSmartScanDateData, setCurrentSmartScanDateData] = useState();
  const [currentSmartScanIdData, setCurrentSmartScanIdData] = useState();
  const [previousSmartScanIdData, setPreviousSmartScanIdData] = useState();
  const userData = useSelector((state) => state.userData);

  function populateWellnessScoreSummery(data) {
    if (data) {
      setCurrentWellnessScoreSummeryData(data.currentDentalCondition);
      setPrevWellnessScoreSummeryData(data.previousDentalCondition);
      setSmartScanWellnessScoreChangeData(data.scoreChange);
      setPreviousSmartScanDateData(data.previousSmartScanDate);
      setCurrentSmartScanDateData(data.currentSmartScanDate);
      setCurrentSmartScanIdData(data.currentSmartScanId);
      setPreviousSmartScanIdData(data.previousSmartScanId);
      setSmartScanScore(data.currentDentalCondition.score);
    }
  }

  function resetWellnessScoreSummery() {
    setCurrentWellnessScoreSummeryData();
    setPrevWellnessScoreSummeryData();
    setSmartScanWellnessScoreChangeData();
    setPreviousSmartScanDateData();
    setCurrentSmartScanDateData();
    setCurrentSmartScanIdData();
    setPreviousSmartScanIdData();
  }

  useEffect(() => {
    if (smartScanId > 0) {
      getSmartScanWellnessScoreSummery();
    }
  }, [smartScanId]);

  function getSmartScanWellnessScoreSummery() {
    dispatch(showLoadingSpinner());
    let params = {
      smartScanId: smartScanId
    };
    getSmartScanWellnessScoreSummery_Ajax(
      patientId,
      params,
      function (response) {
        dispatch(hideLoadingSpinner());

        if (response && response.success && response.data) {
          populateWellnessScoreSummery(
            response.data.smartScanWellnessScoreSummery
          );
        } else {
          resetWellnessScoreSummery();
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }
  useEffect(() => {
    if (patientId > 0) {
      getSmartScanWellnessScoreSummery();

      if (refreshGraph) {
        const interval = setInterval(() => {
          getSmartScanWellnessScoreSummery();
        }, IntervalTimes.Minute_MS_30);

        return () => clearInterval(interval);
      }
    }
  }, [patientId]);

  function checkForStatusColor() {
    if (currentWellnessScoreSummeryData) {
      if (currentWellnessScoreSummeryData.status === "Poor") {
        return "#a52834";
      } else if (currentWellnessScoreSummeryData.status === "Moderate") {
        return "#635821";
      } else {
        return "#206529";
      }
    }
  }

  function handleScoreClick(smartScanId, PatientId) {
    if (smartScanId > 0 && PatientId > 0) {
      navigate("/smart-scan-wellness-score", {
        state: {
          smartScanId: smartScanId,
          patientId: PatientId,
          userData: userData
        }
      });
    }
  }

  function smartScanRequestBtnClicked() {
    navigate("/smart-scan-submitted-records", {
      state: {
        smartScanId: smartScanId,
        showBackButton: true
      }
    });
  }

  function hasCurrentWellnessScore() {
    if (currentWellnessScoreSummeryData) {
      return (
        <div className="col-12 col-lg-4 content-center text-center d-flex flex-wrap pe-0 pe-lg-4 mb-3 mb-lg-0 wellness-current-score wellness-score1">
          <div className="d-flex flex-wrap justify-content-between justify-content-lg-center align-items-center mb-3 w-100">
            <div className="ps-0 pe-3 px-md-2">
              {prevWellnessScoreSummeryData ? (
                <div
                  tabIndex={0}
                  onClick={() =>
                    handleScoreClick(previousSmartScanIdData, patientId)
                  }
                >
                  <span className="fs-3 fw-bold d-block">
                    {prevWellnessScoreSummeryData.score}
                  </span>
                  <small className="text-muted d-block">
                    {formatDateTime(
                      previousSmartScanDateData,
                      DateTimeFormats.MM_DD_YY
                    )}
                  </small>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="ps-0 pe-3 px-md-2"
              tabIndex={0}
              onClick={() =>
                handleScoreClick(currentSmartScanIdData, patientId)
              }
            >
              <small className="text-muted d-block mb-2">Current</small>
              <span
                className="lh-1 display-2 d-block mb-2"
                style={{ marginTop: "-20px", color: checkForStatusColor() }}
              >
                {currentWellnessScoreSummeryData.score}
              </span>
              <span
                className="bg-green-light fs-5 fw-bold d-block px-2 rounded-pill"
                style={{ color: checkForStatusColor() }}
              >
                {currentWellnessScoreSummeryData.status}
              </span>
              <small className="text-muted d-block mt-2">
                {formatDateTime(
                  currentSmartScanDateData,
                  DateTimeFormats.MM_DD_YY
                )}
              </small>
            </div>

            <div className="ps-0 pe-0 px-md-2">
              {smartScanWellnessScoreChangeData ? (
                <>
                  <span className="fs-3 text-primary fw-bold d-block">
                    {smartScanWellnessScoreChangeData >= 0 ? (
                      <i className="bi bi-arrow-up fs-5"></i>
                    ) : (
                      <i className="bi bi-arrow-down fs-5"></i>
                    )}{" "}
                    {Math.abs(smartScanWellnessScoreChangeData)}
                  </span>
                  <small className="text-muted d-block">change</small>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {smartScanId > 0 ? (
            <div>
              <div className="score-text fw-bold w-100">
                Your oral health is{" "}
                <span style={{ color: checkForStatusColor() }}>
                  {currentWellnessScoreSummeryData.status}
                </span>
                , expand the options below to learn what's impacting your score.
              </div>
              <a
                className="btn btn-outline-secondary btn-xs d-table mx-auto mt-3"
                tabIndex={0}
                href="#"
                onClick={() => {
                  smartScanRequestBtnClicked();
                }}
              >
                Smart Scan Request
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      return <DummyWellnessSummeryScore />;
    }
  }

  return <>{hasCurrentWellnessScore()}</>;
};
export default WellnessScoreSummery;
