import React, { useEffect, useState } from "react";
import iconChat from "./../../assets/images/icon-chat.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShouldOpenVAChatFromCICI } from "../../redux/actions/userData";

const ChatToaster = (props) => {
  let { onlineCsrs, setOnlineCsrs, getChatRoom, toggleShowOnlineCSRToastList } =
    props;

  const [visibleOnlineCsrs, setVisibleOnlineCsrs] = useState([]);
  const [sholdShowCsrToast, setSholdShowCsrToast] = useState(false);

  const dispatch = useDispatch();
  const shouldOpenVAChatFromCICI = useSelector((state) => {
    return state?.userData?.shouldOpenVAChatFromCICI;
  });

  function closeCsrToast(csr) {
    if (csr && csr.staffId) {
      let tempOnlineCsrs = [...onlineCsrs];
      let tempCsr = tempOnlineCsrs.find((c) => c.staffId == csr.staffId);

      if (tempCsr) {
        tempCsr.isToastHidden = true;
      }

      setOnlineCsrs([...tempOnlineCsrs]);
    }
  }

  function setScroll() {
    let elements = document.getElementsByTagName("body");
    elements[0].setAttribute("id", "overflow-scroll");
  }

  useEffect(() => {
    if (onlineCsrs && Array.isArray(onlineCsrs) && onlineCsrs.length > 0) {
      let tempOnlineCsrs = [...onlineCsrs];
      let tempVisibleOnlineCsrs = tempOnlineCsrs.filter(
        (csr) => !csr.isToastHidden
      );
      setVisibleOnlineCsrs([...tempVisibleOnlineCsrs]);
    }
  }, [onlineCsrs]);

  useEffect(() => {
    let width = window.innerWidth;
    if (width >= 768) {
      setSholdShowCsrToast(true);
    }
  }, []);

  function iconChatClicked() {
    if (sholdShowCsrToast) {
      toggleShowOnlineCSRToastList();
    }
    setSholdShowCsrToast(true);
  }

  useEffect(() => {
    if (shouldOpenVAChatFromCICI && visibleOnlineCsrs.length > 0) {
      window.$("#va-toast0").click();
      dispatch(setShouldOpenVAChatFromCICI(false));
    }
  });

  return (
    <>
      <div className="chat-toast toast-container">
        {visibleOnlineCsrs &&
          sholdShowCsrToast &&
          Array.isArray(visibleOnlineCsrs) &&
          visibleOnlineCsrs.length > 0 &&
          visibleOnlineCsrs.map((csr, index) => {
            return (
              <div key={index} className="border-0 rounded-0">
                <div
                  className="toast show border-0"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div className="toast-header bg-transparent p-0 border-0 rounded-0 position-absolute top-0 end-0">
                    <button
                      type="button"
                      className="btn-close p-2 m-0"
                      aria-label="Close"
                      onClick={() => {
                        closeCsrToast(csr);
                      }}
                    ></button>
                  </div>
                  <div
                    id={"va-toast" + index}
                    data-bs-toggle="modal"
                    data-bs-target="#chatModal"
                    className="toast-body flex-1 d-flex"
                    onClick={() => {
                      getChatRoom(csr);
                      toggleShowOnlineCSRToastList();
                      setScroll();
                    }}
                  >
                    <div className="right">
                      <h4 className="name text-truncate">Virtual Assistant</h4>
                      <div className="fs-10 text-muted">
                        Hi there! I’m available to chat if you have any
                        questions!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <button
        id="va-chat-button"
        type="button"
        aria-label="Chat with virtual assistant button"
        data-bs-miss
        className="chat-button bg-white p-2 mb-4 me-4 border-0 rounded-circle position-fixed bottom-0 end-0 shadow"
        onClick={() => {
          iconChatClicked();
        }}
        tabIndex={0}
      >
        <img className="w-100 h-100 object-contain" src={iconChat} alt="" />
        <span className="badge bg-primary fs-6 fw-normal rounded-pill px-1 mt-3 position-absolute top-0 start-100 translate-middle"></span>
      </button>
    </>
  );
};

export default ChatToaster;
