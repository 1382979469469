import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../../assets/css/style.css";
import SetPasswordForm from "../../components/SetPassword/SetPasswordForm";
import dentalComLogo from "./../../assets/images/dental-com-logo.svg";
import { DentalDotComPageUrl } from "../../constants";
import { getHomeLandingPageUrl, getLogoUrl } from "../../ApplicationSettings";

export default function SetPassword() {
  const { state } = useLocation();
  const logo = require("./../../assets/images/" + getLogoUrl());

  return (
    <>
      <body>
        <header className="py-2">
          <nav className="navbar navbar-expand-lg p-0">
            <div className="container-fluid">
              <a
                className="hover-element navbar-brand p-0"
                href={getHomeLandingPageUrl() + window?.location?.search}
                onClick={(e) => e.preventDefault()}
              >
                <img src={logo} alt="Logo" width="120" height="50" />
              </a>
            </div>
          </nav>
        </header>
        <section className="account-section sign-in-section py-4 py-md-5 v-middle">
          <SetPasswordForm
            formData={state?.signUpFormData}
            insuranceData={state?.insuranceFormData}
            isSignUpExternalPatient={state?.isSignUpExternalPatient}
            consentFormData={state?.consentFormData}
          />
        </section>

        {/* <!-- scripts --> */}
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
          crossOrigin="anonymous"
        >
          {" "}
        </script>
      </body>
    </>
  );
}
