import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../../assets/css/style.css";
import InsuranceForm from "../../components/InsuranceInformation/InsuranceForm";
import {
  InsuranceFormFields,
  DateTimeFormats,
  InsuranceCaptureOptionValues,
  DentalDotComPageUrl
} from "../../constants";
import {
  isValidDate,
  formatDateTime,
  queryStringToJSON
} from "../../helpers/utils";
import { useSelector } from "react-redux";
import { getHomeLandingPageUrl, getLogoUrl } from "../../ApplicationSettings";

const Insurance = () => {
  const { state } = useLocation();
  const isSignUpExternalPatient = state?.isSignUpExternalPatient;
  const insurancePrefillData = state?.insurancePrefillData;
  const backFromNext = state?.backFromNext;
  const [signUpFormData, setSignUpFormData] = useState();
  const [type, setType] = useState(null);
  const navigate = useNavigate();
  const logo = require("./../../assets/images/" + getLogoUrl());
  const { setValue } = useForm({ mode: "onChange" });
  const [searchParams] = useSearchParams();
  const [selectedCarrierId, setSelectedCarrierId] = useState(0);
  const [initialInsuranceData, setInitialInsuranceData] = useState({});
  const showConsentFormInRegistrationFlow = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.showConsentFormInRegistrationFlow
  );

  function checkForQueryParams() {
    let params = queryStringToJSON();
    if (params) {
      if (params.type) {
        setType(params.type);
      }
    }
  }

  const shouldShowProductTypeInInsuranceFormInRegistration = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.showProductTypeInInsuranceFormInRegistration
  );

  const validRelations = useSelector(
    (state) => state?.appSettings?.settingsData?.validRelations
  );

  useEffect(() => {
    setSignUpFormData(state?.signUpFormData);
    checkForQueryParams();
  }, []);

  useEffect(() => {
    if (isSignUpExternalPatient) {
      populateInsuranceData(state?.insurancePrefillData);
    }
  }, [isSignUpExternalPatient]);

  useEffect(() => {
    if (backFromNext) {
      setSignUpFormData(state?.patientData);
    }
  }, [backFromNext]);

  const navigateBack = () => {
    if (state) {
      navigate(
        { pathname: "/sign-up", search: `?${searchParams}` },
        {
          state: {
            patientData: signUpFormData,
            insurancePrefillData: insurancePrefillData,
            backFromNext: true,
            isSignUpExternalPatient: isSignUpExternalPatient
          }
        }
      );
    } else {
      navigate(-1);
    }
  };

  const navigateSkip = () => {
    if (signUpFormData) {
      signUpFormData.insurance = InsuranceCaptureOptionValues.No;
      initializeInsuranceFormData({});
    }
    let pathName;
    if (showConsentFormInRegistrationFlow) {
      pathName = "/registration-consent-form";
    } else {
      pathName = "/set-password";
    }
    navigate(
      { pathname: pathName, search: `?${searchParams}` },
      {
        state: {
          signUpFormData,
          isSignUpExternalPatient,
          from: "/sign-up"
        }
      }
    );
  };

  function initializeInsuranceFormData(insuranceFormFields) {
    Object.keys(InsuranceFormFields).forEach((key) => {
      let value = insuranceFormFields[InsuranceFormFields[key]];
      insuranceFormFields[InsuranceFormFields[key]] = value ?? undefined;
    });
    setInitialInsuranceData(insuranceFormFields);
  }

  const populateInsuranceData = (insuranceData) => {
    if (insuranceData) {
      setValue(InsuranceFormFields.State, insuranceData.state);
      setValue(InsuranceFormFields.City, insuranceData.insuredCity);
      setValue(InsuranceFormFields.Zip, insuranceData.zip);
      setValue(InsuranceFormFields.FirstName, insuranceData.insuredFirstName);
      setValue(InsuranceFormFields.LastName, insuranceData.insuredLastName);
      if (isValidDate(insuranceData.insuredDob)) {
        setValue(
          InsuranceFormFields.DateOfBirth,
          formatDateTime(insuranceData.insuredDob, DateTimeFormats.YYYY_MM_DD)
        );
      }
      setValue(InsuranceFormFields.Address, insuranceData.insuredAddress);
      setValue(InsuranceFormFields.Employer, insuranceData.employerName);
      setValue(InsuranceFormFields.Gender, insuranceData.gender);
      setValue(
        InsuranceFormFields.InsuranceCarrierName,
        insuranceData.carrierName
      );
      setValue(
        InsuranceFormFields.PatientRelation,
        insuranceData.relationToPatient
      );
      setValue(InsuranceFormFields.SubscriberID, insuranceData.insuredId);
      setValue(
        InsuranceFormFields.ApplyToAllDependents,
        insuranceData.isApplicableToDependent
      );
    }
  };

  const handleSubmit = (insuranceFormData) => {
    let pathName;
    if (showConsentFormInRegistrationFlow) {
      pathName = "/registration-consent-form";
    } else {
      pathName = "/set-password";
    }
    navigate(
      { pathname: pathName, search: `?${searchParams}` },
      {
        state: {
          signUpFormData,
          insuranceFormData,
          isSignUpExternalPatient,
          from: "/insurance"
        }
      }
    );
  };

  return (
    <>
      <header className="py-2">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            <a
              className="hover-element navbar-brand p-0"
              href={getHomeLandingPageUrl()}
              onClick={(e) => e.preventDefault()}
            >
              <img src={logo} alt="Logo" width="120" height="50" />
            </a>
          </div>
        </nav>
      </header>
      <body>
        <section className="account-section sign-in-section py-4 py-md-5 v-middle">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="progress mb-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "66.67%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <InsuranceForm
                  insuranceFormData={
                    insurancePrefillData
                      ? insurancePrefillData
                      : initialInsuranceData
                  }
                  showApplyToDependentsOption={false}
                  shouldShowProductTypeField={
                    shouldShowProductTypeInInsuranceFormInRegistration
                  }
                  signUpFormData={signUpFormData}
                  validRelations={validRelations}
                  isInSignUpPageFlow={true}
                  handleBackButton={navigateBack}
                  onSubmit={handleSubmit}
                  type={type}
                  handleSkipRegistrationInsuranceCapture={navigateSkip}
                  selectedCarrierId={selectedCarrierId}
                  setSelectedCarrierId={setSelectedCarrierId}
                />
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
};

export default Insurance;
