import React, { useEffect, useRef, useState } from "react";
import MessageBox from "./MessageBox";
import ReceivedMessage from "./ReceivedMessage";
import SentMessage from "./SentMessage";
import userImgPlaceholder from "./../../assets/images/chat-cici.png";
import CICICavity from "./../../assets/images/CC_Cavities 2.png";
import CICIOralHygiene from "./../../assets/images/CC_OralHygiene 2.png";
import CICIBrokeTooth from "./../../assets/images/CC_BrokenTeeth 2.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientInfo } from "../../helpers/authManager";
import {
  getAIChatMessages_Ajax,
  getAIChatRoom_Ajax,
  getSmartScanCaseReport_Ajax,
  saveAIChatMessages_Ajax
} from "../../helpers/requests";
import {
  convertURLToLink,
  getLocalTimezoneOffset,
  handleApiErrors,
  isEmptyArray,
  showAlertDialouge
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  CICILinks,
  IntervalTimes,
  LinkTypes,
  Pagination,
  PatientOrigins,
  TextToSpeechAvailableForLanguages
} from "../../constants";
import {
  sendMessageToCICI_Ajax,
  sendMessageToOfficeCICI_Ajax
} from "../../helpers/requestsExternal";
import moment from "moment";
import { getSmartScanInitialMessageForCICI } from "../../helpers/AIChatUtils";
import { ReactTyped } from "react-typed";
import { getBaseUrl, getPatientOrigin } from "../../ApplicationSettings";
import { startSpeaking, stopSpeaking } from "../../helpers/TextToSpeech";

const ChatSection = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [chatRoomId, setChatRoomId] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesGroupedByDate, setMessagesGroupedByDate] = useState([]);
  const patientInfo = getPatientInfo();
  const exisitngMessagesRef = useRef([]);
  const [smartScanCaseReport, setSmartScanCaseReport] = useState();
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [messagesToType, setMessagesToType] = useState([]);
  const replyMessagesObjRef = useRef([]);
  const [currentMessagesToTypeIndex, setCurrentMesssagesToTypeIndex] =
    useState(-1);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentDateString, setCurrentDateString] = useState("");
  const [playingMsgId, setPlayingMsgId] = useState(0);

  const messageTypes = {
    sent: "sent",
    received: "received"
  };

  const chatWrapperRef = useRef();
  const chatListDivRef = useRef();

  const isDemoPatient = useSelector((state) =>
    state?.appSettings?.settingsData?.demoPatientIds?.includes(
      patientInfo?.patid
    )
  );

  const demoSmartScanIds = useSelector((state) => {
    return state?.appSettings?.settingsData?.demoSmartScanIds;
  });

  const demoMessages = [
    [
      {
        aiChatMessageId: 1,
        message:
          "Hello, " +
          patientInfo?.fullName +
          ". Your scan results are back, and your overall score is " +
          smartScanCaseReport?.overAllCondition?.score +
          ", which indicates there are some areas of potential concern. Let’s go over the results:",
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 2,
        message:
          "On an upper front tooth, an area of stain was noted. It may be an indication of an early cavity or it may just be a stain that does not involve a cavity. In cases like this, the tooth is often “observed” or “watched” over time. If the stain grows over time, like 6 months, it is likely a cavity and will need some type of treatment. The evaluation over time needs to be done by a dentist.",
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 3,
        message:
          'It appears that you may have a broken tooth on the lower right, and the break appears to have broken through the enamel covering, which could make the tooth susceptible to decay. <br><img style="width: 350px" src="' +
          CICIBrokeTooth +
          '"/>',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 4,
        message:
          'Your oral hygiene needs improvement with moderate to severe plaque buildup throughout your mouth. Plaque buildup may lead to more serious gum and bone disease if not removed. There is also indication of gum inflammation on your upper right side, which may be due to the plaque buildup. It’s likely you need a professional cleaning to take care of the problems. You should also be more aware of and more consistent with proper oral hygiene.<br><img style="width: 350px" src="' +
          CICIOralHygiene +
          '"/>',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 5,
        message:
          "Not all broken teeth need treatment, but only a dentist can help you make the right decision. So, I recommend that you speak with a dentist. At the same time, you can discuss the stain on your front tooth and get guidance on your gum inflammation and oral hygiene in general.",
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 6,
        message:
          "Dental.com offers convenient virtual consultations with licensed dentists. If you'd like to consult with a dentist now, click here <a href=\"" +
          getOnDemandCallPageLink() +
          '">[link]</a>. They are available 24/7/365.',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 7,
        message: "Can I answer any other questions?",
        createdOn: moment.utc(),
        senderUserId: null
      }
    ],
    [
      {
        aiChatMessageId: 1,
        message:
          "Hello, " +
          patientInfo?.fullName +
          ". Your dental scan results with an overall score of " +
          smartScanCaseReport?.overAllCondition?.score +
          ", which means there are some areas of concern that you should be aware of. Let’s take a look at those areas:",
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 2,
        message:
          'It appears that you might have a cavity on a lower right back tooth. If left untreated, cavities will typically continue to grow, leading to bigger problems. The same tooth also appears to be broken. <br><img style="width: 350px" src="' +
          CICICavity +
          '"/>',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 3,
        message:
          'Another tooth also appears to be broken. The break looks like it is through the enamel covering of the tooth, which may make it susceptible to cavities. <br><img style="width: 350px" src="' +
          CICIBrokeTooth +
          '"/>',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 4,
        message:
          'Your oral hygiene could use some improvement with moderate plaque buildup and gum inflammation at various areas of your mouth. Better home care will usually alleviate these problems. <br><img style="width: 350px" src="' +
          CICIOralHygiene +
          '"/>',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 5,
        message:
          "Especially because of the potential cavity, I recommend that you speak with a dentist, who can also evaluate the status of any broken teeth.",
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 6,
        message:
          "Dental.com offers convenient virtual consultations with licensed dentists. If you'd like to consult with a dentist now, click here <a href=\"" +
          getOnDemandCallPageLink() +
          '">[link]</a>. They are available 24/7/365.',
        createdOn: moment.utc(),
        senderUserId: null
      },
      {
        aiChatMessageId: 7,
        message: "Can I answer any other questions?",
        createdOn: moment.utc(),
        senderUserId: null
      }
    ]
  ];

  useEffect(() => {
    if (!chatListDivRef.current || !chatWrapperRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (isAITyping) {
        scrollToBottom();
      }
    });

    resizeObserver.observe(chatListDivRef.current);
    return () => resizeObserver.disconnect();
  }, [chatListDivRef, chatWrapperRef, isAITyping]);

  useEffect(() => {
    if (state?.patientId > 0) {
      getSmartScanCaseReport(state.patientId, state.smartScanId);
    }
  }, [state]);

  useEffect(() => {
    if (smartScanCaseReport) {
      getAIChatRoom();
    }
  }, [smartScanCaseReport]);

  useEffect(() => {
    if (chatRoomId > 0) {
      getAIChatMessages(chatRoomId);
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (messages.length > 0) {
      processMessages(messages);
    }
  }, [messages]);

  function handleTranslateClick(event) {
    if (
      event.target.id === "google_translate_element" ||
      event.target.closest("#google_translate_element")
    ) {
      event.preventDefault();
    }
  }

  const voiceSupportedLanguages = TextToSpeechAvailableForLanguages.join();

  const initGoogleTranslateElement = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: voiceSupportedLanguages,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
      },
      "google_translate_element"
    );
  };

  function getSmartScanCaseReport(patientId, smartScanId = 0) {
    let params = {
      includeDetails: true,
      patId: patientId,
      shouldGetLatestSmartScanCaseReport: smartScanId > 0 ? false : true,
      includeToothDetails: true
    };

    dispatch(showLoadingSpinner());
    getSmartScanCaseReport_Ajax(
      smartScanId,
      params,
      function (resposne) {
        dispatch(hideLoadingSpinner());

        if (resposne?.success && resposne?.data?.smartScanCaseReportVM) {
          setSmartScanCaseReport(resposne?.data?.smartScanCaseReportVM);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }
  function processMessages(messages) {
    let newProcessedMessages = [];
    messages.forEach((msg) => {
      let processedMessage = {
        ...msg
      };

      let sentTime = msg?.createdOn;
      if (sentTime) {
        processedMessage.date = moment(sentTime).format("dddd MMM DD, YYYY");
        processedMessage.time = moment(sentTime).format("LT");
      } else {
        processedMessage.date = moment().format("dddd MMM DD, YYYY");
        processedMessage.time = moment().format("LT");
      }

      if (msg?.senderUserId > 0) {
        processedMessage.type = messageTypes.sent;
        processedMessage.userImg = patientInfo?.photoId;
      } else {
        processedMessage.type = messageTypes.received;
        processedMessage.userImg = userImgPlaceholder;
      }

      newProcessedMessages.push(processedMessage);
    });

    if (newProcessedMessages.length > 0) {
      exisitngMessagesRef.current = [...newProcessedMessages];
      let msgGroupedByDate = newProcessedMessages.groupBy("date");
      setMessagesGroupedByDate(msgGroupedByDate);
    }
  }

  function getAIChatRoom() {
    dispatch(showLoadingSpinner());
    setIsProcessing(true);

    getAIChatRoom_Ajax(
      patientInfo.userId,
      function (response) {
        if (response?.success && response?.data?.chatRoom?.aiChatRoomId > 0) {
          setChatRoomId(response?.data?.chatRoom?.aiChatRoomId);
        } else if (!response.success && response.message) {
          showAlertDialouge("Error", response.message);
        }

        dispatch(hideLoadingSpinner());
      },
      function (err) {
        handleApiErrors(err);
        dispatch(hideLoadingSpinner());
        setIsProcessing(false);
      }
    );
  }

  function scrollToBottom(height) {
    if (!height) {
      height = chatWrapperRef.current.scrollHeight;
    }

    chatWrapperRef.current.scrollTo(0, height);
  }

  function handleScrollOnGettingOldMessage() {
    if (exisitngMessagesRef.current.length <= Pagination.AIChatItemPerPage) {
      scrollToBottom();
    } else {
      scrollToBottom(50);
    }
  }

  function getAIChatMessages(chatRoomId) {
    if (isDemoPatient && demoSmartScanIds?.length > 0) {
      setIsProcessing(false);
      setIsAITyping(false);
      var demoSmartScanIdIndex = -1;

      demoSmartScanIdIndex = demoSmartScanIds?.findIndex(
        (id) => id == smartScanCaseReport?.smartScanId
      );

      if (demoSmartScanIdIndex >= 0) {
        setMessages(demoMessages[demoSmartScanIdIndex % demoMessages.length]);
        setTimeout(handleScrollOnGettingOldMessage, 50);
        return;
      }
    }

    if (chatRoomId > 0) {
      dispatch(showLoadingSpinner());
      setIsProcessing(true);
      let pageOffset = 0;

      if (exisitngMessagesRef?.current?.length > 0) {
        pageOffset = exisitngMessagesRef.current.length;
      }

      let params = {
        pageOffset: pageOffset,
        itemPerPage: Pagination.AIChatItemPerPage,
        localTimezoneOffset: getLocalTimezoneOffset(),
        sendInitialMessagesOnly: true
      };

      if (smartScanCaseReport?.smartScanId) {
        params.smartScanId = smartScanCaseReport.smartScanId;
      }

      getAIChatMessages_Ajax(
        chatRoomId,
        params,
        function (response) {
          if (
            response.success &&
            !isEmptyArray(response?.data?.chatMessageVMs)
          ) {
            let result = response.data.chatMessageVMs;
            let existingMessage = exisitngMessagesRef.current;

            setMessages([...result, ...existingMessage]);
            setTimeout(handleScrollOnGettingOldMessage, 50);
          }

          setIsProcessing(false);
          dispatch(hideLoadingSpinner());
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setIsProcessing(false);
          handleApiErrors(err);
        }
      );
    }
  }

  function getOnDemandCallPageLink() {
    let params = {};
    if (smartScanCaseReport?.smartScanId) {
      params.smartScanId = smartScanCaseReport.smartScanId;
    }

    if (smartScanCaseReport?.patId) {
      params.patientId = smartScanCaseReport.patId;
    }

    let url = getBaseUrl() + "/see-dentist?" + $.param(params);

    return url;
  }

  function getLink(linkType) {
    if (linkType == LinkTypes.SeeDentistNow) {
      return getOnDemandCallPageLink();
    }
  }

  function convertLinks(message) {
    if (message) {
      for (let [key, value] of Object.entries(CICILinks)) {
        let url = getLink(value);

        if (url) {
          let element = `<a href="${url}">${key.toString()}</a>`;
          message = message.replace(key, element);
        }
      }

      return message;
    }
  }

  function saveMessages(messageToSave, messageType) {
    if (messageToSave && messageType) {
      let messageData = messageToSave.map((message) => {
        let msgObj = {};

        msgObj.smartScanId = smartScanCaseReport?.smartScanId;
        msgObj.message = convertURLToLink(message);
        msgObj.message = convertLinks(message);

        if (messageType == messageTypes.sent) {
          msgObj.senderUserId = patientInfo.userId;
        }

        return msgObj;
      });

      let currentMsg = [...exisitngMessagesRef.current];

      saveAIChatMessages_Ajax(
        chatRoomId,
        JSON.stringify(messageData),
        function (response) {
          if (response.success && !isEmptyArray(response?.data?.chatMessages)) {
            let newChatMessages = response.data.chatMessages;
            if (messageType == messageTypes.received) {
              startTypingReplyMessages(newChatMessages);
            } else {
              setMessages([...currentMsg, ...newChatMessages]);
            }
          } else if (!response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function startTypingReplyMessages(messages) {
    if (messages.length > 0) {
      setIsAITyping(true);
      let messagesToType = messages.map((msg) => msg.message);
      setMessagesToType([...messagesToType]);
      replyMessagesObjRef.current = [...messages];
      setCurrentMesssagesToTypeIndex(0);
    }
  }

  useEffect(() => {
    if (
      currentMessagesToTypeIndex >= 0 &&
      currentMessagesToTypeIndex <= messagesToType.length - 1 &&
      messagesToType.length > 0
    ) {
      setCurrentMessage(messagesToType[currentMessagesToTypeIndex]);
    } else if (
      currentMessagesToTypeIndex >= messagesToType.length &&
      messagesToType.length > 0
    ) {
      setCurrentMesssagesToTypeIndex(-1);
      setIsAITyping(false);
      setMessagesToType([]);
      replyMessagesObjRef.current = [];
    }
  }, [currentMessagesToTypeIndex]);

  function addTypedMessageToExistingMessages(index) {
    if (
      replyMessagesObjRef.current.length > 0 &&
      index < replyMessagesObjRef.current.length &&
      index >= 0
    ) {
      let existingMessage = exisitngMessagesRef.current;
      let newMessage = replyMessagesObjRef.current[index];
      setMessages([...existingMessage, newMessage]);
    }
  }

  useEffect(() => {
    let currentDate = moment().format("dddd MMM DD, YYYY");
    if (messagesGroupedByDate.length > 0 && isAITyping) {
      let currentDateIndex = messagesGroupedByDate.findIndex((m) => {
        return m.field == currentDate;
      });

      if (currentDateIndex >= 0) {
        currentDate = "";
      }
    }

    setCurrentDateString(currentDate);
  }, [messagesGroupedByDate, isAITyping]);

  function sendMessage(inputMsg) {
    if (inputMsg.length > 0) {
      setIsWaitingForReply(true);

      let currentMsg = [...exisitngMessagesRef.current];

      let msgObj = {
        message: inputMsg,
        senderUserId: patientInfo.userId
      };

      if (currentMsg.length > 0) {
        saveMessages([inputMsg], messageTypes.sent);
        currentMsg.push(msgObj);
        setMessages([...currentMsg]);
        setTimeout(scrollToBottom, 50);
      }
      setIsProcessing(true);

      let formData = {
        msg: inputMsg
      };

      let onSuccess = function (response) {
        if (!isEmptyArray(response)) {
          let nonEmptyMsg = response.filter((m) => m.length > 0);
          saveMessages(nonEmptyMsg, messageTypes.received);
        } else if (typeof response == "string") {
          saveMessages([response], messageTypes.received);
        }
        setIsProcessing(false);
        setIsWaitingForReply(false);
      };

      let onError = function (err) {
        handleApiErrors(err);
        setIsProcessing(false);
        setIsWaitingForReply(false);
      };

      let origin = getPatientOrigin();
      if (origin == PatientOrigins.DDDOffice || origin == PatientOrigins.DDD) {
        formData["provider_id"] = patientInfo.officeReferralProviderId;
        sendMessageToOfficeCICI_Ajax(
          JSON.stringify(formData),
          onSuccess,
          onError
        );
      } else {
        formData["scan_id"] = 1;
        sendMessageToCICI_Ajax(formData, onSuccess, onError);
      }
    }
  }

  function handleScrollToTop(event) {
    if (
      isDemoPatient &&
      demoSmartScanIds?.includes(smartScanCaseReport?.smartScanId)
    ) {
      return;
    }

    if (event && event.target) {
      let element = event.target;

      if (element.scrollTop == 0 && chatRoomId > 0) {
        getAIChatMessages(chatRoomId);
      }
    }
  }

  function onBeforeUnload() {
    stopSpeaking();
  }

  function getTranslatedLanguageCode() {
    const cookieValue = `; ${document.cookie}`;
    const parts = cookieValue.split(`; googtrans=`);
    
    if (parts.length >= 2) {
      const googtransValue = parts[1].split(';').shift();
      const targetLang = googtransValue.split('/')[2];
      
      return targetLang || null;
    }
    
    return null;
  }

  function handleGoogleTranslatorOnPageLoad() {
    let googleTranslateElementScript = document.createElement("script");
    googleTranslateElementScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(googleTranslateElementScript);
    document.body.classList.add("notranslate");
    window.googleTranslateElementInit = initGoogleTranslateElement;
    document.addEventListener("click", handleTranslateClick);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    handleGoogleTranslatorOnPageLoad();

    return () => {
      stopSpeaking();
      document.removeEventListener(handleTranslateClick);
      document.body.classList.remove("notranslate");
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  function playBtnClicked(message, messageId, elementId) {
    if (message) {
      
      let targetLang = getTranslatedLanguageCode();

      startSpeaking(
        message,
        function () {
          setPlayingMsgId(messageId);
        },
        function () {
          setPlayingMsgId(0);
        },
        elementId,
        targetLang
      );
    }
  }

  function pauseBtnClicked() {
    stopSpeaking(function () {
      setPlayingMsgId(0);
    });
  }

  function isMsgPlaying(messageId, playingMsgId) {
    return messageId == playingMsgId;
  }

  function renderMessages(messages) {
    return messages.map((message, index) => {
      if (message.type == messageTypes.received) {
        return (
          <>
            <ReceivedMessage
              key={message + index}
              id={"receivedMsg-" + index}
              message={message?.message}
              userImg={message?.userImg}
              isPlaying={isMsgPlaying(message.aiChatMessageId, playingMsgId)}
              playBtnClicked={() => {
                playBtnClicked(message.message, message.aiChatMessageId, "receivedMsg-" + index);
              }}
              pauseBtnClicked={pauseBtnClicked}
            />
          </>
        );
      } else {
        return (
          <>
            <SentMessage
              key={message + index}
              message={message?.message}
              userImg={message?.userImg}
              senderName={patientInfo.fullName}
            />
          </>
        );
      }
    });
  }

  function onTypingCompleted() {
    addTypedMessageToExistingMessages(currentMessagesToTypeIndex);
    setCurrentMesssagesToTypeIndex((value) => value + 1);
  }

  function renderAIMessageTypingSection() {
    return (
      <>
        {isAITyping ? (
          <>
            {currentDateString ? (
              <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                <span>{currentDateString}</span>
              </div>
            ) : (
              <></>
            )}
            <div className="chatbox">
              <img
                src={userImgPlaceholder}
                className="author-img rounded-circle"
              />
              <div className="chat-msg msg-output" style={{ display: "table" }}>
                <ReactTyped
                  strings={[currentMessage]}
                  showCursor={false}
                  typeSpeed={IntervalTimes.MS_25}
                  onComplete={onTypingCompleted}
                  fadeOut={true}
                  contentType="html"
                ></ReactTyped>
              </div>
              <br />
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      <section className="sec-profile-setting py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div class="gth-main-header d-flex justify-content-between align-items-center mb-3">
                <h1>Chat with CICI</h1>
              </div>
              <div className="bg-white border-radius-xlg p-4">
                <div className="chatbox-wrapper py-2">
                  <div className="chatbox-body translate">
                    <div id="container">
                      <div
                        ref={chatWrapperRef}
                        id="chat-wrapper"
                        onScroll={handleScrollToTop}
                      >
                        <div id="main-container" ref={chatListDivRef}>
                          {!isEmptyArray(messagesGroupedByDate) &&
                            messagesGroupedByDate.map((message, index) => {
                              return (
                                <div
                                  key={message?.field + index}
                                  className="chat-date-wrapper"
                                >
                                  <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                                    <span>{message?.field}</span>
                                  </div>
                                  {renderMessages(message.groupList)}
                                </div>
                              );
                            })}
                          {isWaitingForReply ? (
                            <div className="chatbox loading-animation">
                              <img
                                src={userImgPlaceholder}
                                className="author-img"
                              />
                              <div className="chat-msg msg-output">
                                <div className="messages__item messages__item--typing">
                                  <span className="messages__dot" />
                                  <span className="messages__dot" />
                                  <span className="messages__dot" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {renderAIMessageTypingSection()}
                        </div>
                      </div>
                      <MessageBox
                        isWaitingForReply={isWaitingForReply}
                        isProcessing={isProcessing}
                        sendMessage={sendMessage}
                        isAITyping={isAITyping}
                      />
                    </div>
                  </div>
                </div>
                <div className="cicichattranslate" id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatSection;
