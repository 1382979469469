import React, { useState, useEffect, useDebugValue, useRef } from "react";
import "./Navbar.module.css";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import bellIcon from "./../../assets/images/icon-bell.svg";
import profilePic from "./../../assets/images/img-profile-pic.png";
import displayPicture from "./../../assets/images/dp.png";
import provider from "./../../assets/images/provider.png";
import patientImg from "./../../assets/images/patient-img.jpg";
import NoPreviewAvailable from "./../../assets/images/No_preview_available.jpg";
import Notification from "./Notification";

import {
  removeTokenData,
  savePatientAndFamilyMembersList,
  setIsFamilyMembersListChanged,
  setIsReloadPatientStatusSummary,
  setLoggedInPatientPhotoId
} from "../../redux/actions/userData";
import {
  getSelectedPatientProfile,
  getPatientInfo,
  getTokenData,
  removeSelectedPatientProfile,
  getSelectedPatientId,
  storeSelectedPatientId,
  hasHideNavigationBarInLocalStorage,
  removeTokenData as removeTokenDataFromStorage
} from "../../helpers/authManager";

import {
  getFamilyMembers_Ajax,
  getNotifications_Ajax,
  getPatientEducations_Ajax,
  getPatientsIncompleteReferralSuggestion_Ajax,
  getPatientStatusSummary_Ajax,
  updateEducationsSeenStatus_Ajax,
  updateMedicalRecordsSeenStatus_Ajax,
  updateOnDemandConsultation_Ajax,
  updatePatientNotesSeenStatus_Ajax,
  updatePrescriptionSeenStatus_Ajax,
  updateReferInfoSeenStatus_Ajax
} from "../../helpers/requests";
import {
  getNameInitials,
  handleApiErrors,
  isEmptyArray,
  onImageError
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  saveCurrentPatient,
  saveFamilyMembersList,
  removeCurrentPatient,
  removeFamilyMembersList
} from "../../redux/actions/userData";
import $ from "jquery";
import { IntervalTimes, IS_SEEN } from "../../constants";
import { HubConnectionBuilder } from "@microsoft/signalr";
import useClearSessionData from "../../redux/customHooks/useClearSessionData";
import { getChatHubUrl } from "../../ApplicationSettings";

export default function Navbar() {
  const pgId = useSelector((state) => state.userData.pgId);
  const isCurrentPatientAvailable = useSelector(
    (state) => state.userData.isCurrentPatientAvailable
  );
  const currentPatient = useSelector((state) => state.userData.currentPatient);
  const familyMembersList = useSelector(
    (state) => state.userData.familyMembersList
  );
  const isFamilyMembersListChanged = useSelector(
    (state) => state.userData.isFamilyMembersListChanged
  );

  const isReferralSuggestionCompletionDisabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.OutsideReferralSuggestionCompletionEnabled
  );

  const [patientNotifications, setPatientNotifications] = useState(null);
  const [patientNotificationsCount, setPatientNotificationsCount] = useState(0);
  const [patientStatusSummary, setPatientStatusSummary] = useState(null);
  const [
    patientsIncompleteReferralSuggestions,
    setPatientsIncompleteReferralSuggestions
  ] = useState(null);
  const patientsIncompleteReferralSuggestionsRef = useRef(null);
  const isReloadPatientStatusSummary = useSelector(
    (state) => state.userData.isReloadPatientStatusSummary
  );
  const [patientStatusActionNeeded, setPatientStatusActionNeeded] =
    useState(false);
  const [connection, setConnection] = useState(null);
  const logoUrl = useSelector(
    (state) => state?.appSettings?.settingsData?.logoUrl
  );
  const isSmartScanFeatureAvailable = useSelector(
    (state) => state?.appSettings?.settingsData?.isSmartScanFeatureAvailable
  );

  const hasHideNavbarInAppSetings = useSelector(
    (state) => state?.appSettings?.settingsData?.hideNavigationBar
  );

  const patientAndFamilyMembersList = useSelector((state) => {
    return state.userData.patientAndFamilyMembersList;
  });

  const [patientsListForNavbarDropdown, setPatientsListForNavbarDropdown] =
    useState(patientAndFamilyMembersList);
  const isReferralFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isReferralFeatureEnabled
  );

  var notificationsFetchRequestRunning = false;

  const [utcOffset, setUtcOffset] = useState(0);
  const [isDST, setIsDST] = useState(false);
  const patientNotificationsRef = useRef(null);
  const clearSessionData = useClearSessionData();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const logo = require("./../../assets/images/" + logoUrl);
  const shouldShowFormInMenu = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowFormInMenu
  );
  const shouldShowNotesInMenu = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowNotesInMenu
  );
  const shouldShowPrescriptionInMenu = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowPrescriptionInMenu
  );
  const shouldShowVisitHistoryInMenu = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowVisitHistoryInMenu
  );
  const shouldShowInsuranceInformation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowInsuranceInformation
  );
  const shouldShowBillingInformation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowBillingInformation
  );

  useEffect(() => {
    connectChatHub();
    getPatientsAndFamilyMembers();
    getPatientsIncompleteReferralSuggestion();
  }, []);

  useEffect(() => {
    if (patientAndFamilyMembersList) {
      createPatientsListForNavbarDropdown();
    }
  }, [patientAndFamilyMembersList]);

  useEffect(() => {
    if (isFamilyMembersListChanged) {
      getPatientsAndFamilyMembers();
      // isFamilyMembersListChanged has to be changed to false
      dispatch(setIsFamilyMembersListChanged(false));
    }
  }, [isFamilyMembersListChanged]);

  useEffect(() => {
    if (isReloadPatientStatusSummary) {
      getPatientsIncompleteReferralSuggestion();
      getPatientStatusSummary();
      dispatch(setIsReloadPatientStatusSummary(false));
    }
  }, [isReloadPatientStatusSummary]);

  useEffect(() => {
    if (currentPatient) {
      getPatientsIncompleteReferralSuggestion();
      getPatientStatusSummary();
    }
  }, [currentPatient]);

  useEffect(() => {
    if (patientStatusSummary) {
      setPatientStatusActionNeeded(
        patientStatusSummary.unseenOrIncompleteReferrals ||
          patientStatusSummary.noOfUnseenPatientNoteIds > 0 ||
          patientStatusSummary.noOfUnseenPrescriptionIds > 0 ||
          patientStatusSummary.noOfUnseenMedicalRecordIds > 0 ||
          patientStatusSummary.noOfUnseenEducationIds > 0
      );
    } else {
      setPatientStatusActionNeeded(false);
    }
  }, [patientStatusSummary]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveNewNotificationMessage", () => {
            getPatientNotifications();
          });
        })
        .catch((e) => {
          handleApiErrors(e);
        });
    }
  }, [connection]);

  function connectChatHub() {
    var tokenData = getTokenData();

    const newConnection = new HubConnectionBuilder()
      .withUrl(getChatHubUrl(), {
        accessTokenFactory: () => tokenData.token
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }

  function getPatientStatusSummary() {
    var patient = currentPatient;
    if (patient && patient.patid) {
      getPatientStatusSummary_Ajax(
        patient.patid,
        function (response) {
          if (response && response.success && response.data) {
            var patientStatusSummaryData = { ...response.data };

            patientStatusSummaryData.noOfUnseenPatientNoteIds = 0;

            if (
              patientStatusSummaryData.unseenProgressNoteIds &&
              Array.isArray(patientStatusSummaryData.unseenProgressNoteIds)
            ) {
              var arr = patientStatusSummaryData.unseenProgressNoteIds;
              patientStatusSummaryData.noOfUnseenPatientNoteIds += arr.length;
            }

            if (
              patientStatusSummaryData.unseenNoteIds &&
              Array.isArray(patientStatusSummaryData.unseenNoteIds)
            ) {
              var arr = patientStatusSummaryData.unseenNoteIds;
              patientStatusSummaryData.noOfUnseenPatientNoteIds += arr.length;
            }

            if (
              patientStatusSummaryData.unseenMedicalFormPatientIds &&
              Array.isArray(
                patientStatusSummaryData.unseenMedicalFormPatientIds
              )
            ) {
              var arr = patientStatusSummaryData.unseenMedicalFormPatientIds;
              patientStatusSummaryData.noOfUnseenMedicalFormPatientIds =
                arr.length;
            }

            if (
              patientStatusSummaryData.unseenMedicalRecordIds &&
              Array.isArray(patientStatusSummaryData.unseenMedicalRecordIds)
            ) {
              var arr = patientStatusSummaryData.unseenMedicalRecordIds;
              patientStatusSummaryData.noOfUnseenMedicalRecordIds = arr.length;
            }

            if (
              patientStatusSummaryData.unseenEducationIds &&
              Array.isArray(patientStatusSummaryData.unseenMedicalRecordIds)
            ) {
              var arr = patientStatusSummaryData.unseenEducationIds;
              patientStatusSummaryData.noOfUnseenEducationIds = arr.length;
            }

            if (
              patientStatusSummaryData.unseenPrescriptionIds &&
              Array.isArray(patientStatusSummaryData.unseenPrescriptionIds)
            ) {
              var arr = patientStatusSummaryData.unseenPrescriptionIds;
              patientStatusSummaryData.noOfUnseenPrescriptionIds = arr.length;
              patientStatusSummaryData.unseenPrescriptionIdsStr = arr.join();
            }

            var unseenOrIncompleteReferrals =
              patientsIncompleteReferralSuggestionsRef.current != null ||
              (patientStatusSummaryData &&
                patientStatusSummaryData.unseenReferInfoIds &&
                Array.isArray(patientStatusSummaryData.unseenReferInfoIds) &&
                patientStatusSummaryData.unseenReferInfoIds.length > 0);

            patientStatusSummaryData.unseenOrIncompleteReferrals =
              unseenOrIncompleteReferrals;

            setPatientStatusSummary(patientStatusSummaryData);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function getPatientsIncompleteReferralSuggestion() {
    var patient = currentPatient;
    if (patient && patient.patid && !isReferralSuggestionCompletionDisabled) {
      getPatientsIncompleteReferralSuggestion_Ajax(
        patient.patid,
        function (response) {
          if (response && response.success) {
            setPatientsIncompleteReferralSuggestions(response.data.suggestion);
            patientsIncompleteReferralSuggestionsRef.current =
              response.data.suggestion;
          } else {
            setPatientsIncompleteReferralSuggestions(null);
            patientsIncompleteReferralSuggestionsRef.current = null;
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function updateReferInfoSeenStatus() {
    if (
      patientStatusSummary &&
      patientStatusSummary.unseenReferInfoIds &&
      Array.isArray(patientStatusSummary.unseenReferInfoIds) &&
      patientStatusSummary.unseenReferInfoIds.length > 0
    ) {
      var params = {
        Status: IS_SEEN,
        ReferInfoIds: patientStatusSummary.unseenReferInfoIds
      };

      updateReferInfoSeenStatus_Ajax(
        JSON.stringify(params),
        function (response) {
          if (response && response.success) {
            dispatch(setIsReloadPatientStatusSummary(true));
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function updatePrescriptionSeenStatus() {
    if (patientStatusSummary && patientStatusSummary.unseenPrescriptionIdsStr) {
      var params = {
        Status: IS_SEEN
      };

      updatePrescriptionSeenStatus_Ajax(
        patientStatusSummary.unseenPrescriptionIdsStr,
        params,
        function (response) {
          if (response && response.success) {
            dispatch(setIsReloadPatientStatusSummary(true));
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function updatePatientNotesSeenStatus() {
    if (
      patientStatusSummary &&
      ((patientStatusSummary.unseenProgressNoteIds &&
        Array.isArray(patientStatusSummary.unseenProgressNoteIds) &&
        patientStatusSummary.unseenProgressNoteIds.length > 0) ||
        (patientStatusSummary.unseenNoteIds &&
          Array.isArray(patientStatusSummary.unseenNoteIds) &&
          patientStatusSummary.unseenNoteIds.length > 0))
    ) {
      var params = {
        Status: IS_SEEN,
        ProgressNoteIds: patientStatusSummary.unseenProgressNoteIds,
        NoteIds: patientStatusSummary.unseenNoteIds
      };

      updatePatientNotesSeenStatus_Ajax(
        JSON.stringify(params),
        function (response) {
          if (response && response.success) {
            dispatch(setIsReloadPatientStatusSummary(true));
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function updateMedicalRecordsSeenStatus() {
    if (
      patientStatusSummary &&
      patientStatusSummary.unseenMedicalFormPatientIds &&
      Array.isArray(patientStatusSummary.unseenMedicalRecordIds) &&
      patientStatusSummary.unseenMedicalRecordIds.length > 0
    ) {
      var params = {
        Status: IS_SEEN,
        MedicalRecordIds: patientStatusSummary.unseenMedicalRecordIds
      };

      updateMedicalRecordsSeenStatus_Ajax(
        JSON.stringify(params),
        function (response) {
          if (response && response.success) {
            dispatch(setIsReloadPatientStatusSummary(true));
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function updateEducationsSeenStatus() {
    if (
      patientStatusSummary &&
      patientStatusSummary.unseenEducationIds &&
      Array.isArray(patientStatusSummary.unseenEducationIds) &&
      patientStatusSummary.unseenEducationIds.length > 0
    ) {
      var params = {
        Status: IS_SEEN,
        EducationIds: patientStatusSummary.unseenEducationIds
      };

      updateEducationsSeenStatus_Ajax(
        JSON.stringify(params),
        function (response) {
          if (response && response.success) {
            dispatch(setIsReloadPatientStatusSummary(true));
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function getPatientNotifications() {
    if (!notificationsFetchRequestRunning) {
      var unseenOnly = 1;
      notificationsFetchRequestRunning = true;

      getNotifications_Ajax(
        unseenOnly,
        function (response) {
          notificationsFetchRequestRunning = false;
          setPatientNotifications(response.data);
          setPatientNotificationsCount(response.data.totalCount);
          patientNotificationsRef.current = response.data;
        },
        function (err) {
          notificationsFetchRequestRunning = false;
          handleApiErrors(err);
        }
      );
    }
  }

  function getPatientsAndFamilyMembers() {
    var patientInfo = getPatientInfo();

    if (patientInfo && patientInfo.patid) {
      var onlyFamilyMembersOptions = false;
      dispatch(showLoadingSpinner());
      getFamilyMembers_Ajax(
        onlyFamilyMembersOptions,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            var familyMembers = [];

            response.data.map((member) => {
              if (member.patid == patientInfo.patid) {
                dispatch(setLoggedInPatientPhotoId(member.photoId));
              }

              if (member.patid != patientInfo.patid) {
                familyMembers.push(member);
              }
            });
            dispatch(saveFamilyMembersList(familyMembers));
            dispatch(savePatientAndFamilyMembersList(response.data));
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function createPatientsListForNavbarDropdown() {
    let selectedPatId = getSelectedPatientId();
    if (!isEmptyArray(patientAndFamilyMembersList) && selectedPatId) {
      var selectedPatient = null;
      var familyMembers = [];
      patientAndFamilyMembersList.forEach((member) => {
        if (currentPatient) {
          if (currentPatient.patid == member.patid) {
            selectedPatient = member;
          } else {
            familyMembers.push(member);
          }
        } else if (member.patid == selectedPatId) {
          selectedPatient = member;
        } else {
          familyMembers.push(member);
        }
      });

      if (selectedPatient == null) {
        let loggedInPatient = getPatientInfo();
        selectedPatient = patientAndFamilyMembersList.find(
          (p) => p.patid == loggedInPatient.patid
        );
        if (selectedPatient?.patid > 0) {
          storeSelectedPatientId(selectedPatient.patid);
        }
      }
    }
    dispatch(saveCurrentPatient(selectedPatient));
    setPatientsListForNavbarDropdown(familyMembers);
  }

  function selectPatientProfile(SelectedMember) {
    var familyMembers = [...patientAndFamilyMembersList];
    var currentPatientDetails = { ...currentPatient };
    familyMembers = familyMembers.filter((member) => {
      if (member.patid == SelectedMember.patid) {
        currentPatientDetails = member;
        return false;
      } else {
        return true;
      }
    });
    if (currentPatientDetails?.patid > 0) {
      storeSelectedPatientId(currentPatientDetails?.patid);
    }
    dispatch(saveCurrentPatient(currentPatientDetails));
    setPatientsListForNavbarDropdown(familyMembers);
    // dispatch(setIsFamilyMembersListChanged(true));
  }

  function logout() {
    clearSessionData();
    navigate("/login");
  }

  function getSelectedPatientNameClasses() {
    var classes =
      "nav-link dropdown-toggle user-profile text-violet d-flex flex-wrap align-items-center";

    if (
      !familyMembersList ||
      (Array.isArray(familyMembersList) && familyMembersList.length == 0)
    ) {
      classes += " dropdown-toggle-hide-arrow";
    }

    return classes;
  }

  function hideSideNavBar() {
    $("#navbarSupportedContent").removeClass("show");
    $("#sideNavOpenButton").attr("aria-expanded", "false");
    $("#sideNavCloseButton").attr("aria-expanded", "false");
  }

  function reRenderNotifications() {
    if (patientNotificationsRef.current) {
      setPatientNotifications({ ...patientNotificationsRef.current });
    }
  }

  function shouldHideNavbar() {
    return hasHideNavbarInAppSetings || hasHideNavigationBarInLocalStorage();
  }
  return (
    <>
      {!shouldHideNavbar() && (
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            {location.pathname != "/" &&
            location.pathname != "/sign-up" &&
            location.pathname != "/set-password" &&
            location.pathname != "/forgot-password" ? (
              <Link
                to="/dashboard"
                className="navbar-brand p-0"
                href="javascript:void(0);"
                tabIndex={0}
              >
                <img
                  src={logo}
                  alt="Dental dot com logo"
                  width="120"
                  height="50"
                />
              </Link>
            ) : (
              <Link to="/" className="navbar-brand p-0" href="/" tabIndex={0}>
                <img
                  src={logo}
                  alt="Dental dot com logo"
                  width="120"
                  height="50"
                  tabIndex={0}
                />
              </Link>
            )}

            <div className="d-lg-none ms-auto position-relative">
              <a
                className="notification d-inline-block dropdown-toggle"
                href="#"
                tabIndex={0}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  reRenderNotifications();
                }}
              >
                <img src={bellIcon} alt="Bell" />
                {patientNotificationsCount ? (
                  <span className="num fs-6 d-flex flex-wrap justify-content-center align-items-center align-content-center rounded-pill position-absolute top-50 start-100 translate-middle">
                    {patientNotificationsCount}
                  </span>
                ) : (
                  <></>
                )}
              </a>
              <Notification
                patientNotifications={patientNotifications}
                getPatientNotifications={getPatientNotifications}
                setPatientNotifications={setPatientNotifications}
                patientNotificationsCount={patientNotificationsCount}
                setPatientNotificationsCount={setPatientNotificationsCount}
                isDST={isDST}
                utcOffset={utcOffset}
                patientNotificationsRef={patientNotificationsRef}
                tabIndex={0}
              />
            </div>

            <button
              id="sideNavOpenButton"
              className="navbar-toggler fs-1 p-0 ms-3 text-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              tabIndex={0}
            >
              <i className="bi bi-list" tabIndex={0}></i>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <button
                id="sideNavCloseButton"
                className="navbar-toggler d-lg-none d-table px-2 py-0 ms-auto"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                tabIndex={0}
              >
                <i className="bi bi-x-lg fs-5 text-muted"></i>
              </button>

              {location.pathname != "/" &&
                location.pathname != "/sign-up" &&
                location.pathname != "/set-password" &&
                location.pathname != "/forgot-password" && (
                  <div className="inner-wrapper ms-auto">
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item dropdown d-none d-lg-flex position-relative">
                        <a
                          className="nav-link notification dropdown-toggle"
                          href="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            reRenderNotifications();
                          }}
                        >
                          <img src={bellIcon} alt="" />
                          {patientNotificationsCount ? (
                            <span className="num fs-6 d-flex flex-wrap justify-content-center align-items-center align-content-center rounded-pill position-absolute top-50 start-100 translate-middle">
                              {" "}
                              {patientNotificationsCount}{" "}
                            </span>
                          ) : (
                            <></>
                          )}
                        </a>
                        <Notification
                          patientNotifications={patientNotifications}
                          getPatientNotifications={getPatientNotifications}
                          setPatientNotifications={setPatientNotifications}
                          patientNotificationsCount={patientNotificationsCount}
                          setPatientNotificationsCount={
                            setPatientNotificationsCount
                          }
                          isDST={isDST}
                          utcOffset={utcOffset}
                          patientNotificationsRef={patientNotificationsRef}
                        />
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle text-secondary"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span style={{ marginRight: "5px" }}>
                            {"Patient Info"}
                          </span>
                          {patientStatusActionNeeded ? (
                            <i
                              style={{ color: "red" }}
                              className="fa fa-exclamation"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <></>
                          )}
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            {isSmartScanFeatureAvailable ? (
                              <Link
                                onClick={() => {
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/smart-scan-wellness-score"
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {"Wellness Score"}
                                </span>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </li>
                          {isReferralFeatureEnabled && (
                            <li>
                              <Link
                                onClick={() => {
                                  updateReferInfoSeenStatus();
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/referrals"
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {"Referrals"}
                                </span>
                                {patientStatusSummary &&
                                patientStatusSummary.unseenOrIncompleteReferrals ? (
                                  <i
                                    style={{ color: "red" }}
                                    className="fa fa-exclamation"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <></>
                                )}
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              onClick={() => {
                                updateMedicalRecordsSeenStatus();
                                hideSideNavBar();
                              }}
                              className="dropdown-item"
                              to="/uploads"
                            >
                              {"Uploads "}
                              {patientStatusSummary &&
                              patientStatusSummary.noOfUnseenMedicalRecordIds &&
                              patientStatusSummary.noOfUnseenMedicalRecordIds >
                                0 ? (
                                <span className="badge bg-gold fs-6 fw-normal rounded-pill px-1">
                                  {
                                    patientStatusSummary.noOfUnseenMedicalRecordIds
                                  }
                                </span>
                              ) : (
                                <></>
                              )}
                            </Link>
                          </li>
                          {shouldShowFormInMenu && (
                            <li>
                              <Link
                                onClick={() => {
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/forms"
                              >
                                Forms
                              </Link>
                            </li>
                          )}
                          {shouldShowNotesInMenu && (
                            <li>
                              <Link
                                onClick={() => {
                                  updatePatientNotesSeenStatus();
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/notes"
                              >
                                {"Notes "}
                                {patientStatusSummary &&
                                patientStatusSummary.noOfUnseenPatientNoteIds &&
                                patientStatusSummary.noOfUnseenPatientNoteIds >
                                  0 ? (
                                  <span className="badge bg-gold fs-6 fw-normal rounded-pill px-1">
                                    {
                                      patientStatusSummary.noOfUnseenPatientNoteIds
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </Link>
                            </li>
                          )}
                          {shouldShowVisitHistoryInMenu && (
                            <li>
                              <Link
                                onClick={() => {
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/visit-history"
                              >
                                Visit History
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              onClick={() => {
                                updateEducationsSeenStatus();
                                hideSideNavBar();
                              }}
                              className="dropdown-item"
                              to="/education"
                            >
                              {"Education "}
                              {patientStatusSummary &&
                              patientStatusSummary.noOfUnseenEducationIds &&
                              patientStatusSummary.noOfUnseenEducationIds >
                                0 ? (
                                <span className="badge bg-gold fs-6 fw-normal rounded-pill px-1">
                                  {patientStatusSummary.noOfUnseenEducationIds}
                                </span>
                              ) : (
                                <></>
                              )}
                            </Link>
                          </li>
                          {shouldShowPrescriptionInMenu && (
                            <li>
                              <Link
                                onClick={() => {
                                  updatePrescriptionSeenStatus();
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/prescription"
                              >
                                {"Prescription "}
                                {patientStatusSummary &&
                                patientStatusSummary.noOfUnseenPrescriptionIds &&
                                patientStatusSummary.noOfUnseenPrescriptionIds >
                                  0 ? (
                                  <span className="badge bg-gold fs-6 fw-normal rounded-pill px-1">
                                    {
                                      patientStatusSummary.noOfUnseenPrescriptionIds
                                    }
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle text-secondary"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Settings
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              onClick={() => {
                                hideSideNavBar();
                              }}
                              className="dropdown-item"
                              to="/profile-setting"
                            >
                              Profile Settings
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                hideSideNavBar();
                              }}
                              className="dropdown-item"
                              to="/change-password"
                            >
                              Change Password
                            </Link>
                          </li>
                          {shouldShowBillingInformation && (
                            <li>
                              <Link
                                onClick={() => {
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/billing-information"
                              >
                                Billing Information
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              onClick={() => {
                                hideSideNavBar();
                              }}
                              className="dropdown-item"
                              to="/my-dependents"
                            >
                              My Dependents
                            </Link>
                          </li>
                          {shouldShowInsuranceInformation && (
                            <li>
                              <Link
                                onClick={() => {
                                  hideSideNavBar();
                                }}
                                className="dropdown-item"
                                to="/insurance-information"
                              >
                                Insurance
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/login"
                              onClick={logout}
                            >
                              Sign Out
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown order-first order-lg-0 user-dropdown">
                        {currentPatient && (
                          <a
                            className={getSelectedPatientNameClasses()}
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {currentPatient?.photoId ? (
                              <img
                                className="profile-pic me-2 rounded-circle"
                                src={currentPatient?.photoId}
                                onError={onImageError}
                                alt=""
                              />
                            ) : (
                              <div className="profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                                {getNameInitials(currentPatient.fullName)}
                              </div>
                            )}
                            <span className="text-truncate">
                              {currentPatient?.fullName}
                            </span>
                          </a>
                        )}
                        {!isEmptyArray(patientsListForNavbarDropdown) && (
                          <ul
                            className="dropdown-menu shadow"
                            aria-labelledby="navbarDropdown"
                          >
                            {patientsListForNavbarDropdown.map((member) => {
                              return (
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:;"
                                    onClick={() => {
                                      selectPatientProfile(member);
                                      hideSideNavBar();
                                    }}
                                  >
                                    {member?.photoId ? (
                                      <img
                                        className="profile-pic me-2 rounded-circle"
                                        src={member.photoId}
                                        onError={onImageError}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                                        {getNameInitials(member.fullName)}
                                      </div>
                                    )}
                                    <div className="name">
                                      {member?.fullName}
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
