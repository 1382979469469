import React from "react";
import ciciChat from "./../../assets/images/chat-cici.png";
import { Link } from "react-router-dom";

const ChatCard = (props) => {
  const { smartScanId = 0, patientId } = props;
  return (
    <>
      <div className="patient-member-card mb-3">
        <div className="card text-body d-flex flex-row align-items-center border-0 border-radius-xlg p-3">
          <div className="wellness-score-cici tab-focusable-background">
            <img className="mb-2" src={ciciChat} alt="" />
            <h2 className="display-6 mb-3">
              Explore your Wellness Score with Cici
            </h2>
            <p className="fs-3 fw-normal mb-4">
              Your personal Care Coordinator can give your deeper insights about
              your results
            </p>
            <Link
              to="/chat"
              state={{
                smartScanId: smartScanId,
                patientId: patientId
              }}
              className="btn btn-lg btn-light text-secondary px-5"
              tabIndex={0}
            >
              Start Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatCard;
